import {
    Flex,
    Box,
} from '@chakra-ui/react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
  } from '@chakra-ui/react'
import DownloadButton from "../../Components/downloadButton";
import { CheckIfUserIsTeacherOr404 } from '../../../ApiLib/useful-functions';


export function TeacherAnswerKeys() {
    //BLOCKER TO DISALLOW NON TEACHERS FROM ACCESSING
    CheckIfUserIsTeacherOr404();
 
    return (
        <Flex align="center" justify="center" w='auto' h='auto' margin='auto' padding='30px' borderRadius='5px'>
        <Flex padding='80px'>
        <Accordion defaultIndex={[]} allowMultiple background={"#3e7800"}>
            <Text align="center" color="white" fontWeight="bold" sx={{
        textDecoration: 'underline'}}>Answer Key Sheets</Text>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 3: Elemental Flame Test
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/answer-keys/VLab-Education-Flame-Test-ANSWER-KEY.pdf" // Path to the file in the public folder
                    fileName="VLab-Education-Flame-Test-ANSWER_KEY.pdf"
                    buttonText="Download Lab 3 Answer Key"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 4: Hydrate Lab
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/answer-keys/VLab-Education-Hydrate-Lab-ANSWER-KEY.pdf" // Path to the file in the public folder
                    fileName="VLab-Education-Hydrate-Lab-ANSWER_KEY.pdf"
                    buttonText="Download Lab 4 Answer Key"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 5: Precipitate Experiment
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/answer-keys/VLab-Education-Precipitation-ANSWER-KEY.pdf" // Path to the file in the public folder
                    fileName="VLab-Education-Precipitate-ANSWER_KEY.pdf"
                    buttonText="Download Lab 5 Answer Key"
                />
            </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 6: Heat Capacity and Calorimetry
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <DownloadButton
                    fileLocation="/answer-keys/VLab-Education-Heat-Capacity-ANSWER-KEY.pdf" // Path to the file in the public folder
                    fileName="VLab-Education-Heat-Capacity-ANSWER_KEY.pdf"
                    buttonText="Download Lab 6 Answer Key"
                />
            </AccordionPanel>
        </AccordionItem>

        {/* <AccordionItem>
            <AccordionButton>
                <Box as='span' flex='1' textAlign='left' color="white" fontWeight="bold">
                Lab 7: Coming Soon
                </Box>
                <AccordionIcon />
            </AccordionButton>
        </AccordionItem> */}
        
        </Accordion>
        </Flex>
        </Flex>
    );
}