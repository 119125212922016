import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Divider,
} from "@chakra-ui/react";

import { getLabData } from "../../ApiLib/lab-data";
import chemistry_lab_data from "../../vlab-courses/chemistry/labData.json";
import { Student } from "../../ApiLib/getStudentList";
import { LoadingBox } from "./LoadingBox";

// Static Labs Data
const labs = [
  { title: "Lab 3: Element Flame Test", labId: "lab3", courseId: "2" },
  { title: "Lab 4: Hydrate Experiment", labId: "lab4", courseId: "2" },
  { title: "Lab 5: Precipitation Experiment", labId: "lab5", courseId: "2" },
  { title: "Lab 6: Heat Capacity Experiment", labId: "lab6", courseId: "2" },
];

interface ListAndCardsProps {
  students: Student[];
}

const ListAndCards: React.FC<ListAndCardsProps> = ({ students }) => {
  const [cards, setCards] = useState<{ student: Student; labId: string }[]>([]);

  const handleStudentClick = (student: Student, labId: string) => {
    if (!cards.some((card) => card.student.playFabId === student.playFabId && card.labId === labId)) {
      setCards([...cards, { student, labId }]);
    }
  };

  const handleRemoveCard = (index: number) => {
    setCards(cards.filter((_, i) => i !== index));
  };

  const handleClearAll = () => {
    setCards([]);
  };

  const groupedCards = cards.reduce((acc, card) => {
    if (!acc[card.labId]) acc[card.labId] = [];
    acc[card.labId].push(card);
    return acc;
  }, {} as Record<string, { student: Student; labId: string }[]>);

  return (
    <Flex
      position="absolute"
      top="60px" /* Header offset */
      bottom="80px" /* Footer offset */
      left="120px" /* Navbar offset */
      right="0" /* Stretch to the right edge */
      overflow="hidden"
    >
      {/* Left Panel */}
      <Box
        width="20%"
        padding="4"
        borderRight="1px solid #e2e8f0"
        overflowY="auto"
        bg="gray.50"
        css={{
          "&::-webkit-scrollbar": { width: "10px" },
          "&::-webkit-scrollbar-thumb": { background: "#888", borderRadius: "5px" },
        }}
      >
        <Heading size="md" marginBottom="4">
          Labs
        </Heading>
        {students.length === 0 ? (
          <Text>No students available</Text>
        ) : (
          <Accordion allowToggle>
            {labs.map((lab) => (
              <AccordionItem key={lab.labId}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {lab.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <List spacing={3}>
                    {students.map((student) => (
                      <ListItem
                        key={student.playFabId}
                        padding="2"
                        cursor="pointer"
                        borderRadius="md"
                        _hover={{ backgroundColor: "teal.100" }}
                        onClick={() => handleStudentClick(student, lab.labId)}
                      >
                        {student.name}
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Box>

      {/* Right Panel */}
      <Box
        flex="1"
        padding="4"
        bg="white"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": { width: "10px" },
          "&::-webkit-scrollbar-thumb": { background: "#888", borderRadius: "5px" },
        }}
      >
        <Heading size="md" marginBottom="4">
          Cards
        </Heading>
        <Flex gap="4" wrap="wrap">
          {Object.keys(groupedCards).length === 0 ? (
            <Text>No cards available</Text>
          ) : (
            labs
              // Filter labs to include only those with cards
              .filter((lab) => groupedCards[lab.labId])
              // Sort labs numerically based on labId
              .sort((a, b) => parseInt(a.labId.replace("lab", "")) - parseInt(b.labId.replace("lab", "")))
              .map(({ labId, title }) => (
                <Box key={labId} width="100%">
                  <Divider marginY="2" />
                  <Heading size="sm" marginBottom="2">
                    {title}
                  </Heading>
                  <Flex gap="4" wrap="wrap">
                    {groupedCards[labId].map((card, index) => (
                      <Card
                        key={index}
                        width="calc(33.333% - 16px)"
                        minWidth="300px"
                        boxShadow="md"
                        border="1px solid #e2e8f0"
                        bg="gray.50"
                      >
                        <CardHeader>
                          <Heading size="sm">{card.student.name}</Heading>
                        </CardHeader>
                        <CardBody>
                          <LabDataTable
                            student={card.student}
                            courseId="2"
                            labId={card.labId}
                          />
                        </CardBody>
                        <CardFooter>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleRemoveCard(index)}
                          >
                            Close
                          </Button>
                        </CardFooter>
                      </Card>
                    ))}
                  </Flex>
                </Box>
              ))
          )}
        </Flex>
        {cards.length > 0 && (
          <Button marginTop="4" colorScheme="red" onClick={handleClearAll}>
            Clear All Cards
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default ListAndCards;



//--------------------------------------------------------------------------------------------------------------------------------------------------



// Create and display the user-entered data for a given lab.
function LabDataTable({ student, courseId, labId, }: { student: Student; courseId: string; labId: string}) {
  const playFabId = student.playFabId;
   const numericalLabId = labId?.substring(3, labId?.length); // Ex. "lab4" -> "4"
   const [isLoading, setIsLoading] = useState(true);



   // Set up interface for user data 
   // @Alex, I think we need to ask the VR crew to only upload the answers, and the units.
   // We don't need the questions stored on every users account
   interface UserInputData {
       [page: string]: {         // ex. = "p1"
           [question: string]: { // ex. = "q3"
               label: string;    // ex. = "What is the melting temperature of lead?"
               answer: string;   // ex. = "327"
               unit: string;     // ex. = "Degrees Celsius"
           };
       };
   }



   type Question = {
       questionNumber: number;
       label: string;
       answer: string;
       unit: string;
   }

   type PageData = {
       pageNumber: number;
       pageTitle: string;
       questions: Array<Question>;
   }

   type LabData = {
       labid: string;
       dataPad: Array<PageData>;
   }

   type StaticLabData = {
       courseid: string;
       labs: Array<LabData>;
   }

   // Declare the static data from vlab-courses/chemistry/labData.json
   // @ts-ignore
   const staticData: StaticLabData = chemistry_lab_data as StaticLabData;


   // Get the user's lab data / answers from playfab. Then send the data to the table. Runs on page load.
   const [fetchedData, setFetchedData] = useState<UserInputData>({});
   useEffect(() => {
       // Get user data from PlayFab
       const getDataForLab = async () => {
           if (courseId !== undefined && numericalLabId !== undefined && labId !== undefined) {
               const getData = await getLabData(playFabId, courseId, numericalLabId.toString());
               setFetchedData(getData);
           } else {
               throw new Error("Either courseId or labId is undefined.");
           }
           setIsLoading(false);
       }
       getDataForLab();
   }, [courseId, labId, numericalLabId, playFabId]
   );

   if (isLoading) {
       return (
           <LoadingBox text={"Loading lab data..."} />
       );
   }

   
   // Replace hardcoded question text below with question from appropriate vlab-courses data. Look up by courseid and labid.
   // You can grep for `vlab-courses` on the repo to see examples of how I did it for course meta data and store data.
   // If unit is null from vlab-courses object for given page / question => replace with users unit from above api call data.
   // Replace `Tablet Page #` with vlab courses pageTitle ("page-title": "Forms Precipitate?") if it exists for the given page

   if (staticData === undefined) {
       return null;
   }
   const staticLabData = staticData.labs.find(lab => lab.labid === numericalLabId);
   if (staticLabData === undefined) {
       return null;
   }

   type AugmentedLabData = PageData & { showUnits: boolean };

   var augmentedLabData: Array<AugmentedLabData> = [];
   if (numericalLabId !== undefined) {
       const labIndex = parseInt(numericalLabId) - 1;
       const pagesArray = Object.entries(fetchedData);
       pagesArray.forEach(([page, questions], pageIndex) => { //Playfab
           // Copy the static data as the base of the augmented lab data.
           if (!augmentedLabData[pageIndex]) {
               augmentedLabData[pageIndex] = {
                   pageNumber: staticLabData.dataPad[pageIndex].pageNumber,
                   pageTitle: staticLabData.dataPad[pageIndex].pageTitle,
                   questions: staticLabData.dataPad[pageIndex].questions,
                   showUnits: false,
               };
           }

           const questionsArray = pagesArray.length > 1 ? Object.entries(questions).slice(1) : Object.entries(questions);
           var pageHasUnits = false;
           questionsArray.forEach(([question, { answer, unit }], questionIndex) => {//Playfab
               if (labIndex >= 0 && labIndex < staticData.labs.length && staticLabData.dataPad.length > 0) {
                   augmentedLabData[pageIndex].questions[questionIndex] = {
                       questionNumber: staticLabData.dataPad[pageIndex].questions[questionIndex].questionNumber,
                       label: staticLabData.dataPad[pageIndex].questions[questionIndex].label,
                       answer: answer || "not submitted",
                       unit: unit !== "" ? unit : getDisplayUnit(staticLabData.dataPad[pageIndex].questions[questionIndex].unit),
                   };
                   if (augmentedLabData[pageIndex].questions[questionIndex].unit !== "") {
                       pageHasUnits = true;
                   }
               } else {
                   throw new Error("Invalid lab index or data structure for updating questions.");
               }
           });
           augmentedLabData[pageIndex].showUnits = pageHasUnits;
       });
   }

   if (Object.keys(fetchedData).length > 0) {
       return (
           <VStack align='left' spacing={2} w='100%' paddingTop={2}>
               {augmentedLabData.map((page: AugmentedLabData, index) => {
                   return (
                       <Box overflowX={'auto'} paddingTop={2}>
                           {/* <TableContainer key={index}> */}
                           <Table variant="simple" colorScheme="blue.1">
                               <Thead>
                                   <Text as="b" p="4px 0" color="#3e7800">{`Tablet Page ${page.pageNumber}: ${page.pageTitle}`}</Text> {/* Students name as the table header */}
                                   <Tr>
                                       <Th color="blue.2">Question</Th>
                                       <Th color="blue.2">Answer</Th>
                                       {page.showUnits && <Th color="blue.2">Unit</Th>}
                                   </Tr>
                               </Thead>
                               <Tbody>
                                   {page.questions.map((question: Question, index: number) => {
                                       const answerStyle = getTableTextStyle(question.answer);
                                       const unitStyle = getTableTextStyle(question.unit);
                                       return (
                                           <Tr key={index}>
                                               <Td>{question.label}</Td>
                                               <Td {...answerStyle}>{question.answer}</Td>
                                               {page.showUnits && <Td {...unitStyle}>{question.unit}</Td>}
                                           </Tr>
                                       );
                                   })}
                               </Tbody>
                           </Table>
                           {/* </TableContainer> */}
                       </Box>
                   );
               }
               )}
           </VStack>
       );
   }

   return (
       <div style={{ backgroundColor: '#B2F5EA', display: 'inline-block', minWidth: 'auto', minHeight: 'auto', borderRadius: '10px', padding: '10px' }}>
           <h1><b>{student.name} has not submitted answers. Please ask them to hit the export data button on the data pad for Lab {numericalLabId} in the VLab simulation.</b></h1>
       </div>
   )
}

function getDisplayUnit(unit: string): string {
   switch (unit) {
       case "USER_INPUT":
       case "DROP_DOWN":
           return "not submitted";
       default:
           return unit;
   }
}

function getTableTextStyle(value: string): object {
   if (value === "not submitted") {
       return {
           color: "gray.500",
           fontStyle: "italic"
       };
   }

   return {
       color: "gray.800",
       fontStyle: "normal"
   };
}
