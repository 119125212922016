import React, { useContext, ReactNode } from 'react';
import './styles/css/App.css';
import { LoginPage } from "./Auth/LoginPage";
import { RegisterPage } from './Auth/RegisterPage';
import { PublicPageWrapper } from './Components/PublicPageWrapper';
import { PrivatePageWrapper } from './Components/PrivatePageWrapper';
import { ResetPasswordPage } from './Auth/ResetPasswordPage';
import RedirectComponent from "./Components/RedirectComponent";
import { removeCharacter } from '../ApiLib/useful-functions';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { MyCourses } from './Dashboard/MyCourses';
import { AccountSettingsPage } from './Account/Account';
// import { StoreHome } from './Store/StoreHome';
import { CourseView } from "./Dashboard/CourseView";
// import { CartView } from "./Store/CartView";
// import { StoreCourseView } from "./Store/CourseView";
import { NotFound } from "./Components/404";
import { SageHillWrapper } from './Components/SageWrapper';
import { TeacherDashboard } from './Schools/Teacher/TeacherDashboard';
import { TeacherResources } from './Schools/Teacher/TeacherResources';
import { StudentAnswers } from './Schools/Teacher/StudentAnswers';
import { LoginContext } from '../stores/useLogin';
import { TeacherAnswerKeys } from './Schools/Teacher/TeacherAnswerKeys';

interface WrapperSelectorProps {
  children: ReactNode;
}

function WrapperSelector({ children }: WrapperSelectorProps) {
  const { Organization } = useContext(LoginContext);
  const org = removeCharacter(Organization,'"');
  if (org === "Sage Hill HS") {
    return (
      <SageHillWrapper>
        {children}
      </SageHillWrapper>
    );
  } else {
    return (
      <PrivatePageWrapper>
        {children}
      </PrivatePageWrapper>
    );
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RedirectComponent redirectLink={"https://www.v-lab-education.com/"} />
    ),
  },
  {
    path: "/login",
    element: (
      <PublicPageWrapper>
        <LoginPage />
      </PublicPageWrapper>
    ),
  },
  {
    path: "/Teacher/Dashboard",
    element: (
      <WrapperSelector>
          <TeacherDashboard />
      </WrapperSelector>
    ),
  },
  {
    path: "/Teacher/Answers",
    element: (
      <WrapperSelector>
          <StudentAnswers />
      </WrapperSelector>
    ),
  },
  {
    path: "/Teacher/Resources",
    element: (
      <WrapperSelector>
        <TeacherResources />
      </WrapperSelector>
    ),
  },
  // {
  //   path: "/Sage/login",
  //   element: (
  //     <SageHillWrapper>
  //       <LoginPage />
  //     </SageHillWrapper>
  //   ),
  // },
  {
    path: "/Teacher/AnswerKeys",
    element: (
      <WrapperSelector>
        <TeacherAnswerKeys />
      </WrapperSelector>
    ),
  },
  {
    path: "/register",
    element: (
      <PublicPageWrapper>
        <RegisterPage />
      </PublicPageWrapper>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <PublicPageWrapper>
        <ResetPasswordPage />
      </PublicPageWrapper>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <RedirectComponent redirectLink={"/dashboard/my-courses"} />
    ),
  },
  {
    path: "/dashboard/my-courses",
    element: (
      <WrapperSelector>
        <MyCourses />
      </WrapperSelector>
    ),
  },
  {
    path: "/account",
    element: (
      <WrapperSelector>
        <AccountSettingsPage />
      </WrapperSelector>
    ),
  },
  // {
  //   path: "/store",
  //   element: (
  //     <PrivatePageWrapper>
  //       <StoreHome />
  //     </PrivatePageWrapper>
  //   ),
  // },
  // {
  //   path: "/cart",
  //   element: (
  //     <PrivatePageWrapper>
  //       <CartView />
  //     </PrivatePageWrapper>
  //   )
  // },
  {
    path: "/course/:courseId/:labId?",
    element: (
      <WrapperSelector>
        <CourseView />
      </WrapperSelector>
    ),
  },
  // {
  //   path: "/store/:courseId",
  //   element: (
  //     <PrivatePageWrapper>
  //       <StoreCourseView />
  //     </PrivatePageWrapper>
  //   ),
  // },
  {
    path: "*",
    element: <NotFound />, // Display 404 page for unknown paths
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
